import React from "react";
import {Clock, Envelope, GeoAlt, Telephone} from 'react-bootstrap-icons';

function Contact() {
    return (
        <section id="contact" className="contact section">

            <div className="container section-title" data-aos="fade-up">
                <h2>Contact</h2>
                <p>We're always available to service your online business needs.</p>
            </div>

            <div className="container" data-aos="fade-up" data-aos-delay="100">
                <div className="col-lg-12">
                    <div className="row gy-4">
                        <div className="col-md-6 col-lg-3">
                            <div className="info-item" data-aos="fade" data-aos-delay="200">
                                <i><GeoAlt/></i>
                                <h3>Address</h3>
                                <p>San Fransisco, CA</p>
                                <p>&nbsp;</p>
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-3">
                            <div className="info-item" data-aos="fade" data-aos-delay="300">
                                <i><Telephone/></i>
                                <h3>Call Us</h3>
                                <p><a href="">+1 5589 55488 55</a></p> {/*//TODO: :wq:wq:get a gvoice number*/}
                                <p>&nbsp;</p>
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-3">
                            <div className="info-item" data-aos="fade" data-aos-delay="400">
                                <i><Envelope/></i>
                                <h3>Email Us</h3>
                                <p><a href="">info@example.com</a></p>
                                <p>&nbsp;</p>
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-3">
                            <div className="info-item" data-aos="fade" data-aos-delay="500">
                                <i><Clock/></i>
                                <h3>Open Hours</h3>
                                <p>Monday - Friday</p>
                                <p>9:00AM - 05:00PM PST</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Contact;
