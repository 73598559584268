import React from 'react';
import ReactDOM from 'react-dom/client';
import Footer from './layouts/Footer';
import Header from './layouts/Header';
import Main from './layouts/Main';
import Aos from 'aos';

import 'aos/dist/aos.css';
import 'bootstrap/dist/css/bootstrap.css';
import './assets/scss/styles.css';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
      <Header />
      <Main />
      <Footer />
      {/*<a href="#" id="scroll-top" className={"scroll-top d-flex align-items-center justify-content-center"}><i className={"bi bi-arrow-up-short"}></i></a>*/}
  </React.StrictMode>
);

const toggleScrolled = () => {
    const selectBody = document.querySelector('body');
    const selectHeader = document.querySelector('#header');
    if (selectHeader != null && !selectHeader.classList.contains('scroll-up-sticky') && !selectHeader.classList.contains('sticky-top') && !selectHeader.classList.contains('fixed-top')) return;
    if(selectBody != null) {
        window.scrollY > 100 ? selectBody.classList.add('scrolled') : selectBody.classList.remove('scrolled');
    }
}

document.addEventListener('scroll', toggleScrolled);
window.addEventListener('load', toggleScrolled);

Aos.init();