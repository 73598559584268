import React from "react";
import {TwitterX, Facebook, Instagram, Linkedin} from 'react-bootstrap-icons';

function Footer() {
    return (
        <footer id="footer" className="footer position-relative light-background">

            <div className="container footer-top">
                <div className="row gy-4">
                    <div className="col-lg-6 col-md-12 footer-about">
                        <a className="logo d-flex align-items-center">
                            <span className="site-name">Kamysz <br/> Consulting Services</span>
                        </a>

                        {/*<div className="social-links d-flex mt-4">*/}
                        {/*    <a href=""><TwitterX/></a>*/}
                        {/*    <a href=""><Facebook/></a>*/}
                        {/*    <a href=""><Instagram/></a>*/}
                        {/*    <a href=""><Linkedin/></a>*/}
                        {/*</div>*/}
                    </div>

                    <div className="col-lg-2 col-6 footer-links">
                        <h4>Our Services</h4>
                        <ul>
                            <li><a>Software Consulting</a></li>
                            <li><a>App Development</a></li>
                            <li><a>UI/UX Design</a></li>
                        </ul>
                    </div>

                    <div className="col-lg-2 col-6 footer-links">
                        <h4>&nbsp;</h4>
                        <ul>
                            <li><a>Web Page Creation</a></li>
                            <li><a>Web Hosting</a></li>
                            <li><a>Marketing</a></li>
                        </ul>
                    </div>

                    <div className="col-lg-2 col-md-12 footer-links">
                        <h4 className="mb-0">Contact Us</h4>
                        <div className="row">
                            {/*<div className="col-6 col-lg-12">*/}
                            {/*    <a href="#"><strong>Phone: </strong><br/><span>+1 5589 55488 55</span></a>*/}
                            {/*</div>*/}
                            {/*<div className="col-6 col-lg-12">*/}
                            {/*    <a href="#"><strong>Email: </strong><br/><span>info@example.com</span></a>*/}
                            {/*</div>*/}
                            <div className="col-6 col-lg-12">
                                <a href="mailto:info@kamz-services.com"><span>info@kamz-services.com</span></a>
                            </div>
                        </div>
                    </div>

                </div>
            </div>

            <div className="container copyright text-center mt-4">
                <p><span>Copyright</span> © 2008 - 2024 <strong className="site-name">KCS</strong><span> All Rights Reserved</span>
                </p>
            </div>

        </footer>
    );
}

export default Footer;
