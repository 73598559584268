import React from "react";
import CallBg from '../../assets/img/call-bg.jpg'

function Contact() {
    return (
        <section id="call-to-action" className="call-to-action section dark-background">

            <img src={CallBg} />

            <div className="container">
                <div className="row justify-content-center" data-aos="zoom-in" data-aos-delay="100">
                    <div className="col-xl-10">
                        <div className="text-center">
                            <h3>Are you ready to join <br className="d-md-none" /> the Digital Age?</h3>
                            <h4>We're ready to partner with you to <br className="d-md-none" /> make it happen.</h4>
                            <p>Our helpful team is always ready <br className="d-md-none" /> to serve your digital needs.</p>
                            <p>Speak to one of our experienced consultants <br className="d-md-none" /> to get started.</p>
                            <a className="cta-btn" href="mailto:info@kamz-services.com">Contact Us</a>
                        </div>
                    </div>
                </div>
            </div>

        </section>
    );
}

export default Contact;
