import React from 'react';

function Header() {
    return (
        <header id="header" className="App-header header d-flex align-items-center fixed-top">
            <div className="container-fluid position-relative d-flex align-items-center justify-content-between">

                <a className="logo d-flex align-items-center me-auto me-xl-0">
                    <h1 className="site-name">KCS</h1><span>.</span>
                </a>

                {/*<a className="btn-get-started" href="index.html#about">Get Started</a>*/}
            </div>
        </header>
    );
}

export default Header;