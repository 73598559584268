import React from "react";

import {Contact, CallToAction, Hero, Faq, AboutUs, Services} from './sections';

function Main() {
    return (
        <main className="main">
            <Hero />
            <AboutUs />
            <Services/>
            <CallToAction/>
            {/*<Faq />*/}
            {/*<Contact/>*/}
        </main>
    );
}

export default Main;
