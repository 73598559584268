import React from "react";
import HeroBg from '../../assets/img/hero-bg.jpg'

function Hero() {
    return (
        <section id="hero" className="hero section dark-background">

            <img src={HeroBg} alt="" data-aos="fade-in"/>

            <div className="container">
                <div className="row">
                    <div className="col-lg-10">
                        <h2 data-aos="fade-up" data-aos-delay="100">Welcome to <br/>Kamysz Consulting Services</h2>
                        <p style={{fontWeight: "bold"}} data-aos="fade-up" data-aos-delay="200">
                            Our helpful team of software engineers <br className="d-sm-none" /> and web developers&nbsp;
                            <br className="d-none d-md-block" />
                            will make sure your business is ready for the digital age
                        </p>
                    </div>
                </div>
            </div>

        </section>
    );
}

export default Hero;
