import React from "react";
import {Briefcase, CodeSquare, Columns, Easel, Globe, RocketTakeoff} from 'react-bootstrap-icons';

function Contact() {
    return (
        <section id="services" className="services section">
            <div className="container section-title" data-aos="fade-up">
                <h2>Services</h2>
                <p>Whatever your needs • You have a partner in the digital age</p>
            </div>

            <div className="container">
                <div className="row gy-4">
                    <div className="col-lg-6 " data-aos="fade-up" data-aos-delay="100">
                        <div className="service-item d-flex">
                            <div className="icon flex-shrink-0"><i><Briefcase/></i></div>
                            <div>
                                <h4 className="title">
                                    <a href="services-details.html" className="stretched-link">
                                        Software Consulting
                                    </a>
                                </h4>
                                <p className="description">
                                    Are you ready to accelerate your company's growth in the digital age?
                                    Your digital assets are only as effective as the business processes
                                    they compliment. We can help you find areas of improvement in your business
                                    and optimize them through the use of modern technologies.
                                </p>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-6 " data-aos="fade-up" data-aos-delay="200">
                        <div className="service-item d-flex">
                            <div className="icon flex-shrink-0"><i><CodeSquare/></i></div>
                            <div>
                                <h4 className="title">
                                    <a href="services-details.html" className="stretched-link">
                                        Application Development
                                    </a>
                                </h4>
                                <p className="description">
                                    Need a web store to sell your amazing products? Perhaps a mobile
                                    application to allow people to schedule appointments or a customer
                                    management system to make sure you understand your customer's needs?
                                    Or even in-house tools to help manage your accounts and keep the books
                                    in order. We have you covered.
                                </p>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-6 " data-aos="fade-up" data-aos-delay="300">
                        <div className="service-item d-flex">
                            <div className="icon flex-shrink-0"><i><Easel/></i></div>
                            <div>
                                <h4 className="title">
                                    <a href="services-details.html" className="stretched-link">
                                        UI/UX Design
                                    </a>
                                </h4>
                                <p className="description">
                                    If your company's branding isn't quite what you expected or you're
                                    just getting started, your companies image should look professional
                                    and represent its values well. We work with customers to make sure
                                    they have a consistent look and feel on any platform they choose
                                    to engage on. Your website, applications, and even down to your
                                    stationary will let customers know who your business is.
                                </p>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-6 " data-aos="fade-up" data-aos-delay="400">
                        <div className="service-item d-flex">
                            <div className="icon flex-shrink-0"><i><Columns/></i></div>
                            <div>
                                <h4 className="title">
                                    <a href="services-details.html" className="stretched-link">
                                        Web Page Creation
                                    </a>
                                </h4>
                                <p className="description">
                                    We work with the best companies to make sure we have modern looking
                                    templates for your websites. This allows us to put them in front of
                                    your customers quicker and at less cost while still allowing us
                                    full customization to make sure everything looks exactly the way you
                                    want it.
                                </p>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-6 " data-aos="fade-up" data-aos-delay="500">
                        <div className="service-item d-flex">
                            <div className="icon flex-shrink-0"><i><Globe/></i></div>
                            <div>
                                <h4 className="title">
                                    <a href="services-details.html" className="stretched-link">
                                        Web Hosting
                                    </a>
                                </h4>
                                <p className="description">
                                    Already have a webpage or are you looking to start your web presence?
                                    We provide you full support top-to-bottom making sure there
                                    is minimal downtime for your customers if an issue should ever
                                    arise. We can help move your domain and website if you're happy
                                    with them currently or we can build them from the ground up
                                    if you're just getting started. We offer a full suite of
                                    solutions for a one stop shop.
                                </p>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-6 " data-aos="fade-up" data-aos-delay="600">
                        <div className="service-item d-flex">
                            <div className="icon flex-shrink-0"><i><RocketTakeoff/></i></div>
                            <div>
                                <h4 className="title">
                                    <a href="services-details.html" className="stretched-link">
                                        Marketing
                                    </a>
                                </h4>
                                <p className="description">
                                    Struggling to get your brand out there? Our team of marketing experts can
                                    help you build your brand by ensuring you can reach your present and future
                                    customers via an omni-channel platform. Whether they are at home on the computer
                                    or traveling with their mobile phone, we'll make sure they are receiving the
                                    latest and greatest news and promotions from you. Your newsletters, blogs,
                                    vlogs, and emails will look professional and present your messaging however
                                    they wish to receive it.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </section>
    );
}

export default Contact;
