import React from "react";
import {Briefcase, CodeSquare, Easel, GraphUpArrow} from 'react-bootstrap-icons';

function Contact() {
    return (
        <section id="about" className="about section light-background">
            <div className="container" data-aos="fade-up" data-aos-delay="100">
                <div className="row gy-5">

                    <div className="col-xl-5 content">
                        <h3>About Us</h3>
                        <h2>A highly motivated team of skilled consultants</h2>
                        <p>
                            At Kamysz Consulting Services we have over 20 years of experience
                            working with nearly every industry imaginable. Our team is ready
                            to assist you with full-stack solutions all the way from
                            web hosting on infrastructure to creating logos and designs to
                            increase brand recognition for your company.
                        </p>
                        <p>
                            Whether you need a marketing website created, an online store
                            to allow customers to purchase your products directly, or applications
                            that look and perform great on both the web as well as natively on
                            mobile devices, we have you covered.
                        </p>
                    </div>

                    <div className="col-xl-7">
                        <div className="row gy-4 icon-boxes">
                            <div className="col-md-6 ">
                                <div className="icon-box h-100">
                                    <i><Briefcase/></i>
                                    <h3>Software Consultants</h3>
                                    <p>
                                        Our Software Consultants have worked in nearly every industry imaginable.
                                        They work diligently to make sure that your business is fully optimized
                                        using the latest and greatest technology has to offer. Our consultants
                                        are available to answer any questions related to how to streamline
                                        your business.
                                    </p>
                                </div>
                            </div>

                            <div className="col-md-6">
                                <div className="icon-box h-100">
                                    <i><CodeSquare/></i>
                                    <h3>Software Engineers</h3>
                                    <p>
                                        The Software Engineers at KCS have worked with nearly every type of
                                        technology imaginable. From the old command line systems to the latest
                                        cloud technologies, our team excels in making sure that any coding,
                                        installation, anti-virus, or maintenance needs you have are completed
                                        quickly, accurately, and at a fair price.
                                    </p>
                                </div>
                            </div>
                        </div>

                        <div className="row gy-4 mt-5">
                            <div className="col-md-6">
                                <div className="icon-box h-100">
                                    <i><Easel/></i>
                                    <h3>Software Designers</h3>
                                    <p>
                                        If you need any help creating logos, finding the right images
                                        for your business, or creating a website to generate more revenue,
                                        our Software Designers excel at making sure your business stands
                                        out in the digital age. They are always happy to assist you in
                                        making sure that you love the look and experience customers
                                        will have with your brand.
                                    </p>
                                </div>
                            </div>

                            <div className="col-md-6">
                                <div className="icon-box h-100">
                                    <i><GraphUpArrow/></i>
                                    <h3>Sales Team</h3>
                                    <p>
                                        Our Sales Team is always professional and courteous making sure
                                        that you are set up with the proper products and services to
                                        accelerate your business' growth potential. They are well educated
                                        in the market and work with you to get the best possible price
                                        while allowing our partnership to grow as your business grows.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Contact;
